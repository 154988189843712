header {
  position: relative;
  text-align: center;
}
h1 {
  font-size: 2.5rem; /* Adjust size as needed */
  color: white; /* Adjust color based on your design */
  text-align: center; /* Center the text */
}

.highlight {
  color: #31b8d4; /* Change to blue for the letters B, U, T, T */
  font-weight: bold; /* Optionally bold the highlighted letters */
}

.slogan {
  font-family: "Dancing Script", cursive; /* Use the running handwriting font */
  font-size: 2rem; /* Adjust size for prominence */
  font-style: italic; /* Make it italic */
  color: white; /* Change as per your design */
  margin-top: 0.5rem; /* Add some space between title and slogan */
}

.header-container {
  position: relative;
}

.gallery-image {
  width: 100%; /* Adjust as necessary */
  max-height: 100%; /* Maintain aspect ratio */
  display: block; /* Remove bottom space from image */
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 50%; /* Center the text vertically */
  left: 50%; /* Center the text horizontally */
  transform: translate(-50%, -50%); /* Adjust for text size */
  color: white; /* Change to your desired text color */
  text-align: center; /* Center the text */
}

.corner-image {
  position: absolute;
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right */
  height: 150px; /* Full screen height */
  width: auto; /* Maintain aspect ratio */
  z-index: 1; /* Ensure the image is above the video */
  margin-right: 2%;
  margin-bottom: 2%;
}
