.container {
  padding: 20px;
}

.main-title {
  margin-bottom: 20px;
}

.card-cover {
  display: flex;
  justify-content: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on larger screens */
  gap: 15px; /* Gap between the items */
  margin: 0 auto; /* Center the grid */
  max-width: 1200px; /* Set a max width for the gallery */
}

.gallery-item {
  overflow: hidden; /* Ensures the image doesn't overflow its container */
  border-radius: 8px; /* Optional: adds rounded corners to images */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.gallery-item:hover {
  transform: scale(1.05); /* Scale the item on hover */
}

.gallery-image {
  width: 100%; /* Make images responsive */
  height: 400px; /* Set a fixed height for all images */
  object-fit: cover; /* Ensures the image fills the area without distortion */
  display: block; /* Remove bottom space from images */
}

/* Media query for medium-sized screens (tablets) */
@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns */
  }

  .gallery-image {
    height: 350px; /* Reduce the image height on smaller screens */
  }
}

/* Media query for small screens (mobile) */
@media (max-width: 576px) {
  .gallery {
    grid-template-columns: 1fr; /* Stack images in a single column */
  }

  .gallery-image {
    height: 300px; /* Further reduce the image height on mobile */
  }
}
